import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  HeaderLintingPages,
  Spinner,
  CardListHoz,
  ListView,
  GradientBanner,
  Seo,
} from '../../components'
import { useContentFilters } from '../../hooks/use-content-filters'
import { getOptionsSelect } from '../../helpers'
import './supplier-resources-styles.scss'

const SupplierResourcesView = ({
  loading,
  data,
  onChangeLocations,
  totalPages,
  totalPosts,
  onHandleNextPage,
  pageSelect,
}) => {
  const { t } = useTranslation()
  const [options, setOptions] = useState([])
  const { locations } = useContentFilters()

  useEffect(() => {
    const selectOptions = [
      {
        options: getOptionsSelect(locations, t('news.all-locations')),
        placeholder: t('news.placeholder-locations'),
        onChange: onChangeLocations,
      },
    ]
    setOptions(selectOptions)
  }, [locations])

  return (
    <div className="container-fluid supplier__container">
      <Seo title={`Season Group: ${t('supplier.title')}`} />
      <div className="supplier__box">
        <HeaderLintingPages
          title={t('supplier.title')}
          selectOptions={options}
        />
        {
          loading ? (
            <Spinner loading={loading} />
          ) : (
            <ListView
              t={t}
              data={data}
              card={CardListHoz}
              classN="col-12 news__col"
              totalPosts={totalPosts}
              onHandleNextPage={onHandleNextPage}
              totalPages={totalPages}
              pageSelect={pageSelect}
            />
          )
        }
      </div>
      <GradientBanner />
    </div>

  )
}

export default SupplierResourcesView
